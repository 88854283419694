import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/self_scheduler/_schedulerPage.scss';
import {
  updateScheduleDetails,
  createScheduleDetails,
  getUTCTime,
  getMessageToShow,
  getTimeWindows
} from 'actions/index';
import { TrackingSpinner } from 'containers/common/tracking_spinner';
import { setPromise, getTransactionRef, decryptedString } from 'containers/common/innovel_util';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import PickersDay, {
  PickersDayProps,
  pickersDayClasses
} from "@mui/lab/PickersDay";
import { Input, TextFieldProps } from "@material-ui/core";
import Button from '@mui/material/Button';
import isSameDay from "date-fns/isSameDay";
import { type } from 'jquery';
import { getTrackingNumber } from 'containers/common/innovel_util';
import TimeWindow from './timeWindow';
import InnovelFlashMessage from 'containers/common/innovel_flash_message.js';
import parse from 'html-react-parser';

const styles2 = {
  backgroundColor: "white !important",
  color: "#000 !important",
  cursor: "pointer !important",
  pointerEvents: "fill !important",
  "&:hover": {
    backgroundColor: "#dceef4 !important",
    cursor: "pointer !important",
    pointerEvents: "fill !important",
  },
  "&:focus": {
    outline: "2px solid !important",
    zIndex: '9'
  }
};
const styles1 = {
  color: "white",
  "&:hover": {
    backgroundColor: "grey",
  }
};
var redDates = [];
export class ScheduleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startSpinner: true,
      deliveryMsg: false,
      alertErrorMsg: null,
      highlightDates: [],
      highlightedDays: [],
      maxDateFlag: '',
      minDateFlag: '',
      isEmptyDate: false,
      isprevDate: false,
      isprevDateEmpty: true,
      isExtscheduleDate: false,
      isDateOpen: false,
      timeWindowAvailable: false,
      timeWindowData: [],
      timeWindowSelected: true,
      timeWindowEligible: false,
      windowStartTime: '',
      windowEndTime: '',
      loadingComplete: false,
      loadSpinner: false,
      greenFlagAvailable: false,
      greenFlagSelected: false,
      hjStartTime: "",
      hjEndTime: "",
      hjScheduleDate: "",
      routeID: "",
      stopNumber: "",
      score: "",
      twTimerValue: 180000,
      requestDate: "",
      activeButton: true,
      tWReturns: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.props.shareMethods(this.updateScheduleDate.bind(this));
  }
  
  renderWeekPickerDay = (
    date,
    selectedDates,
    pickersDayProps
  ) => {
    const matchedStyles = this.state.highlightedDays.reduce((a, v) => {
      return isSameDay(date, v.date) ? v.styles : a;
    }, {});

    return (
      <PickersDay
        {...pickersDayProps}
        sx={{
          ...matchedStyles,
          [`&&.${pickersDayClasses.selected}`]: {
            backgroundColor: "#2A6293 !important",
            zIndex: '9',
            color: '#fff !important',
            outline: '2px solid black !important'
          }
        }}
      />
    );
  };

  //find the diff dates
  configDates = (startDate, endDate) => {
    let dates = []
    //to avoid modifying the original date
    const theDate = new Date(startDate)
    while (theDate <= endDate) {
      dates = [...dates, new Date(theDate)]
      theDate.setDate(theDate.getDate() + 1)
    }
    let fdates = [];
    for (let i = 0; i < dates.length; i++) {
      var d = new Date(dates[i]),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      fdates.push([month, day, year].join('-'));

    }
    dates = fdates;
    return dates
  }

  getDateArr = (greenDates) => {
    this.setState({ scheduledDate: this.props.transactionDetails.scheduleDate ? this.dateFormat(this.props.transactionDetails.scheduleDate) : null, });
    this.setState({ isExtscheduleDate: greenDates.some(el => el._i === this.props.transactionDetails.scheduleDate) ? true : false });

    if (greenDates) {
      let maximumDate = new Date(Math.max.apply(null, greenDates));
      let minimumDate = new Date(Math.min.apply(null, greenDates));
      this.configDates('MAXDate:::::::', maximumDate);
      this.setState({ maxDateFlag: maximumDate, minDateFlag: minimumDate });
      greenDates.forEach((element, i) => {
        const availableDate = new Date(element);
        let tempDate = {
          date: availableDate,
          styles: styles2
        }
        this.state.highlightedDays.push(tempDate)
      });
     
      const currentDate = new Date();
      const scheduleDate = new Date(this.props.transactionDetails.scheduleDate);
     
      let sDate;
      if (currentDate > scheduleDate) {
        sDate = {
          date: scheduleDate,
          styles: styles1
        }
      } else {
        sDate = {
          date: scheduleDate,
          styles: styles2
        }
      }
      this.state.highlightedDays.push(moment(sDate,"YYYY-MM-DD"));
    }
  }

  checkFetureDate = (date) => {
    let fetureDate = new Date();
    let exiScDate = new Date(date);

    if(fetureDate<exiScDate){
      return true
    }
    return false
  }

  getUTCForTimeWindowsCall = (values) => {
    this.setState({activeButton: false});
    this.props.getUTCTime().then(result => {
      if(result.payload.status === 200) {
        sessionStorage.setItem("utcTime", result.payload.request.response)
      }
      else {
        sessionStorage.removeItem("utcTime");
      }
      this.getDCETimeWindows(values);
    }).catch(error => {
      sessionStorage.removeItem("utcTime");
      this.getDCETimeWindows(values);
    });
  }

  getDCETimeWindows = (values) => {
    this.props.getTimeWindows(values).then(result => {
      if(result.payload.status === 200) {
        const reqDate = JSON.parse(result?.payload?.config?.data).deliveryDate
        this.setState({ requestDate: reqDate });
        const response = result.payload.data;
        if(response !== null || response !== undefined) {
          if(Array.isArray(response.serviceResponse) && response.serviceResponse.length) {
            if(response.serviceResponse[0].timeWindowsList !== null && response.serviceResponse[0].timeWindowsList !== undefined &&
              Array.isArray(response.serviceResponse[0].timeWindowsList) && response.serviceResponse[0].timeWindowsList.length) {
              localStorage.setItem("dceWindows", true);            
              localStorage.setItem("timerCount", this.state.twTimerValue);
              localStorage.setItem("timerStartTime", moment.utc(Date.now()));
              this.setState({ timeWindowAvailable: true });
              this.setState({ tWReturns: true });
              this.props.windowsFound(true);
            } else {
              this.noDCEWindowsResponse();
            }
            
          }
          else {
            this.noDCEWindowsResponse();
          }       
          
        } else {
          this.noDCEWindowsResponse();
        } 
      } else {
        this.noDCEWindowsResponse();
        this.setState({ loadingComplete: true, loadSpinner: false, activeButton: true });
      }

      if (this.state.requestDate === this.state.calendarDate) {
        this.setState({ loadingComplete: true, loadSpinner: false, activeButton: true });
      }
    }).catch(error => {
      this.noDCEWindowsResponse();
      if (this.state.requestDate === this.state.calendarDate) {
        this.setState({ loadingComplete: true, loadSpinner: false, activeButton: true });
      }  
    })
  }

  noDCEWindowsResponse = () => {
    localStorage.setItem("dceWindows", false);
    this.setState({ 
      timeWindowAvailable: true, 
      windowStartTime: "",
      windowEndTime: "",
      tWReturns: false
    });

    if(this.props.transactionDetails.hjStartTime &&
      this.props.transactionDetails.hjEndTime &&
      this.props.transactionDetails.hjScheduleDate
      && this.state.calendarDate === this.props.transactionDetails.hjScheduleDate) {
        var hjDate = this.props.transactionDetails.hjScheduleDate;
        var hjStartTime = moment(hjDate + " " + this.props.transactionDetails.hjStartTime, ["YYYY-MM-DD HH:mm"]);
        var hjEndTime = moment(hjDate + " " + this.props.transactionDetails.hjEndTime, ["YYYY-MM-DD HH:mm"]);
        this.props.windowsFound(moment(hjEndTime).diff(hjStartTime, 'hours') > 
        process.env.REACT_APP_TIME_WINDOW_RANGE ? false : true
        );
    }

    this.setState({ timeWindowSelected: true });
  }
  
  componentDidUpdate(prevProps, prevState) {
    localStorage.removeItem('isKeyExit');
    this.props.getMessageToShow({message: '', alertType: null});
    if (
      prevProps.transactionDetails.availableDates !==
      this.props.transactionDetails.availableDates
    ) {
      const values = this.props.transactionDetails.availableDates;
      const arr = _.values(values);
      const greenDates = [];
      let dateLength = arr.length < this.props.transactionDetails.stockThreshold ? arr.length : this.props.transactionDetails.stockThreshold;

      for (let i = 0; i < dateLength; i++) {
        greenDates[i] = moment(arr[i], 'YYYY-MM-DD');
      }

      //Check time window flag to display window label
      //and make dce call
      if(this.props.transactionDetails.trackingOrder.length) {
        if(this.props.transactionDetails.trackingOrder.length && this.props.transactionDetails.timeWindowFlag !== null && this.props.transactionDetails.timeWindowFlag === true) {
          this.setState({ timeWindowEligible: this.props.transactionDetails.timeWindowFlag, hjScheduleDate: this.props.transactionDetails.hjScheduleDate });
          
          if(this.props.transactionDetails.scheduleDate) {
            this.setState({ calendarDate: this.props.transactionDetails.scheduleDate });
            //Validate HJ start and end times to render the label
            if(this.props.transactionDetails.hjStartTime && this.props.transactionDetails.hjEndTime && this.props.transactionDetails.hjScheduleDate) {
              var hjScheduleDate = this.props.transactionDetails.hjScheduleDate;
              var scheduledStartTime = moment.utc(hjScheduleDate + " " + this.props.transactionDetails.hjStartTime, ["YYYY-MM-DD HH:mm"]);
              var scheduledEndTime = moment.utc(hjScheduleDate + " " + this.props.transactionDetails.hjEndTime, ["YYYY-MM-DD HH:mm"]);
              this.setState({ currentTimeWindow: moment(scheduledStartTime).format('h:mm a') + " - " + moment(scheduledEndTime).format('h:mm a')})
              this.setState({ 
                hjStartTime: this.props.transactionDetails.hjStartTime,
                hjEndTime: this.props.transactionDetails.hjEndTime              
              });
            }
            //set the values for DCE call
            var trackingNumbers = [];
            this.props.transactionDetails.trackingOrder.map(tracking => {
              trackingNumbers.push(tracking.trackingNumber);
            });
            let windowValues = {
              deliveryDate: this.props.transactionDetails.scheduleDate,
              trackingNumbers: trackingNumbers,
              transactionRef: getTransactionRef()
            }
            if(moment(this.props.transactionDetails.scheduleDate).isSameOrAfter(Date())) {
              this.setState({ loadingComplete: false, loadSpinner: true });
              //this.getDCETimeWindows(windowValues);
              this.getUTCForTimeWindowsCall(windowValues);
            }
          }
        }
      }

      if (this.props.transactionDetails.scheduleDate) {
        let isFetureDate = this.checkFetureDate(this.props.transactionDetails.scheduleDate);
        let curDate;
        let exiScDate;
        if (isFetureDate) {
          curDate = new Date();
          exiScDate = new Date(this.props.transactionDetails.scheduleDate);
          if(exiScDate.getUTCFullYear() > curDate.getFullYear()){
            greenDates.push(moment(this.props.transactionDetails.scheduleDate, 'YYYY-MM-DD'));
          } else if ((exiScDate.getUTCMonth() + 1) === (curDate.getMonth() + 1)) {
            if (exiScDate.getUTCDate() > curDate.getDate()) {
              greenDates.push(moment(this.props.transactionDetails.scheduleDate, 'YYYY-MM-DD'));
            }
          } else if ((exiScDate.getUTCMonth() + 1) > (curDate.getMonth() + 1)) {
            greenDates.push(moment(this.props.transactionDetails.scheduleDate, 'YYYY-MM-DD'));
          }
        }
      }

      if (
        !(
          this.props.transactionDetails.scheduleDate === null ||
          this.props.transactionDetails.scheduleDate === undefined
        )
      ) {

        this.setState({
         availableDates: this.props.transactionDetails.availableDates,
          scheduledDate: this.props.transactionDetails.scheduleDate ? this.dateFormat(this.props.transactionDetails.scheduleDate) : null,
          //isprevDate: true,
          isprevDate: false,
          highlightDates: greenDates,
          showFlag: true,
          startSpinner: false,
          deliveryMsg: true,
          schDate: this.props.transactionDetails.scheduleDate,
          redHighlightedDate: redDates,
          reschedule: true,
          custService: this.props.transactionDetails.custService,
          sDate: this.props.transactionDetails.scheduleDate ? this.dateFormat(this.props.transactionDetails.scheduleDate) : '',
          stockThreshold: this.props.transactionDetails.stockThreshold
        });
        this.getDateArr(greenDates);
      } else {
        this.setState({
          availableDates: this.props.transactionDetails.availableDates,
          scheduleDate: this.dateFormat(greenDates[0]),
          highlightDates: greenDates,
          showFlag: true,
          startSpinner: false,
          redHighlightedDate: redDates,
          reschedule: false,
          custService: this.props.transactionDetails.custService,
          sDate: this.props.transactionDetails.scheduleDate ? this.dateFormat(this.props.transactionDetails.scheduleDate) : '',
          stockThreshold: this.props.transactionDetails.stockThreshold
        });
        this.getDateArr(greenDates);
      }

      if (!this.props.transactionDetails.trackingOrder.length > 0) {
        this.props.transactionDetails.scheduleDate ? this.setState({ isEmptyDate: false }) : this.setState({ isEmptyDate: true });
      } else {
        let scheduleDatesTraking = this.props.transactionDetails.trackingOrder.map(function (item) { return item.scheduleDate });
        let isSameDate = scheduleDatesTraking.every((val, i, arr) => val === arr[0]);
        if (isSameDate) {
          this.setState({ sDate: this.dateFormat(scheduleDatesTraking[0]) });
        }
      }


    }

  }

  handleChange(dat) {
    this.setState({ isprevDate: false, isprevDateEmpty: false, isExtscheduleDate: true });
    const { highlightDates } = this.state;
    let isDisabledDate = highlightDates.some(x=>x._i===moment(dat).format('YYYY-MM-DD'));
    if(isDisabledDate){
    if (dat === null || dat === undefined || isNaN(dat.valueOf()) || !isDisabledDate) {
      this.setState({ startSpinner: false })
      this.setState({isDateOpen: true});
      this.setState({ isEmptyDate: true });
      this.setState({ timeWindowSelected: false });
      this.setState({ scheduledDate: null });
      localStorage.setItem('isKeyExit', true);
    } else {
      this.setState({ isEmptyDate: false });
      this.setState({isDateOpen: false});
      
      localStorage.setItem('isKeyExit', false);
      let datesflag = false;
      let warnflag = false;
      let date = this.dateFormat(moment(dat).format('YYYY/MM/DD'));
      //const { highlightDates } = this.state;
      for (let i = 0; i < highlightDates.length; i++) {
        let tempDate = highlightDates[i];
        if (tempDate.isSame(date)) {
          datesflag = true;
          break;
        }
      }

      if (datesflag) {
        this.props.disableButton(warnflag);
        let messageObj = { alertType: 'reset', message: '' };
        this.setState({ alertErrorMsg: messageObj.message });
        this.setState({
          scheduledDate: date,
          showFlag: true,
          warnflag: false
        });
        if(this.props.transactionDetails.trackingOrder.length) {
          if(this.props.transactionDetails.timeWindowFlag !== null && this.props.transactionDetails.timeWindowFlag) {
            this.setState({ calendarDate: moment(date).format('YYYY-MM-DD')})
            var trackingNumbers = [];
            this.props.transactionDetails.trackingOrder.map(tracking => {
              trackingNumbers.push(tracking.trackingNumber);
            })
            let windowValues = {
              deliveryDate: moment(date).format('YYYY-MM-DD'),
              trackingNumbers: trackingNumbers,
              transactionRef: getTransactionRef()
            }
            this.setState({ loadingComplete: false, loadSpinner: true, timeWindowSelected: false, timeWindowAvailable: false });
            //this.getDCETimeWindows(windowValues);
            this.getUTCForTimeWindowsCall(windowValues);
          }
        }
      } else {
        for (let i = 0; i < redDates.length; i++) {
          let tempDate = redDates[i];
          if (tempDate.isSame(date)) {
            warnflag = true;
            break;
          }
        }
        if (warnflag) {
          this.props.disableButton(warnflag);
          if (
            this.state.custService === undefined ||
            this.state.custService === null
          ) {
            let messageObj = {
              alertType: 'error',
              message:
                'Unable to fetch the contact number details. Try to refresh the page or come back later.'
            };
            this.setState({ alertErrorMsg: messageObj.message });
          } else {
            this.setState({
              warnflag: true,
              scheduledDate: null
            });
            let messageObj = {
              alertType: 'error',
              message:
                'Please call customer service ' +
                this.state.custService +
                'to schedule a date marked red.'
            };
            this.setState({ alertErrorMsg: messageObj.message });
          }
        } else {
          let messageObj = {
            alertType: 'error',
            message: 'Please choose an available date in GREEN.'
          };
          this.setState({ alertErrorMsg: messageObj.message });
          this.setState({
            showFlag: false,
            scheduledDate: date
          });
        }
      }
    }
    } else {
      this.setState({isDateOpen: false});
      this.setState({ isEmptyDate: true });
      this.setState({ scheduledDate: null });
    }
  }

  handleSubmit(e) {

  }

  isDatePresentInList(selectedDate) {
    let flag = false;
    const { highlightDates } = this.state;
    for (let i = 0; i < highlightDates.length; i++) {
      if (moment(highlightDates[i]).format('YYYY-MM-DD') === selectedDate) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  previous = e => {
    let bulkUrl = localStorage.getItem('bulkUrl');
    var keyObj = localStorage.getItem("key") ? localStorage.getItem("key") : "";
    var decKey = "";
      if(keyObj && keyObj !== undefined && keyObj !== null && keyObj !== "") {
        var keyString = decryptedString(keyObj, process.env.REACT_APP_SECRET);
        decKey = JSON.parse(keyString);
      }
    if(bulkUrl){
      localStorage.removeItem('bulkUrl');
      if(decKey && (decKey?.orderKey !== undefined && decKey?.orderKey !== null) && decKey?.keySource === "email") {
        this.props.history.push('selfschedule?order=' + getTrackingNumber() + '&orderKey=' + decKey?.orderKey);
      } else {
        this.props.history.push('selfschedule?order=' + getTrackingNumber());
      }
    } else {
      this.props.history.push('userselfschedule');
    }
  };

  handleClick = (e) => {
    if(this.props.transactionDetails.timeWindowFlag !== null && this.props.transactionDetails.timeWindowFlag) {
      if(this.state.timeWindowSelected === true) {
        this.setUpdateScheduleDate(e);
      } else {
        if(!this.state.isEmptyDate) {
          this.timeWindowValidation();
        }
      }
    } else {
      this.setUpdateScheduleDate(e);
    }  
  }

  setUpdateScheduleDate = (values) => {
    if (this.state.errorMessage) {
      values.preventDefault();
    } else {
      this.props.getUTCTime().then(result => {
        const httpStatus = result.payload.status
        if(httpStatus === 200) {
          //var response = result.payload.data;
          sessionStorage.setItem("utcTime", result.payload.request.response)
        }
        else {
          sessionStorage.removeItem("utcTime");
        }  
        this.updateScheduleDate()
      }).catch(error => {
        sessionStorage.removeItem("utcTime");
        this.updateScheduleDate()
      }); 
    }
  }

  updateScheduleDate = () => {
    const {unselectedTW} = this.props;
    if (this.state.isprevDateEmpty && 
         this.props.transactionDetails.scheduleDate &&
         !this.state.isExtscheduleDate) {
          return;
    }
    if(!this.state.scheduledDate) {
      this.setState({isEmptyDate: true});
      return;
    }
    const trans = getTransactionRef();
    if (trans === '' || trans === undefined || trans === null) {
      this.props.history.push('/');
    } else {
      let submitValues = {
        scheduleDate: moment(this.state.scheduledDate).format('YYYY-MM-DD'),
        startTime: this.state.windowStartTime,
        endTime: this.state.windowEndTime,
        greenFlagAvailable: this.state.greenFlagAvailable,
        greenFlagSelected: this.state.greenFlagSelected,
        routeID: this.state.routeID,
        stopNumber: this.state.stopNumber,
        score: this.state.score,
        hjStartTime: this.state.hjStartTime,
        hjEndTime: this.state.hjEndTime,
        hjScheduleDate: this.state.hjScheduleDate
      };
      if (unselectedTW && Array.isArray(unselectedTW) && this.state.tWReturns === true) {
        const sameDayTrackingIds = [];
        unselectedTW.forEach(item => {
          if (item.hjScheduleDate === submitValues.scheduleDate) {
            sameDayTrackingIds.push({ trackingNumber: item.trackingNumber });
          }
        });
        submitValues.unselectedTW = sameDayTrackingIds;
      } else {
        const sameDayTrackingIds = [];
        submitValues.unselectedTW = sameDayTrackingIds;
        if (unselectedTW !== null && unselectedTW !== undefined && unselectedTW.length !== 0) {
          unselectedTW.forEach(item => {
            if (item.hjScheduleDate === submitValues.scheduleDate && item.hjStartTime !== submitValues.startTime && item.hjEndTime !== submitValues.endTime) {
              submitValues.startTime = "";
              submitValues.endTime = "";
              submitValues.greenFlagAvailable = false;
              submitValues.greenFlagSelected = false;
              submitValues.routeID = "";
              submitValues.stopNumber = "";
              submitValues.score = "";
            }
          })
        }
      }
      if (submitValues.scheduleDate === 'Invalid date') {
        this.setState({ isEmptyDate: true });
      } else {
        this.setState({ isEmptyDate: false });
      }
      submitValues.transactionRef = getTransactionRef();
      submitValues.firstAvailableDate = moment(this.state.highlightDates[0]).format(
          'YYYY-MM-DD'
      );
      if (this.state.reschedule) {
        if (
          !this.isDatePresentInList(
            moment(this.state.scheduledDate).format('YYYY-MM-DD')
          )
        ) {
          const messageObj = {
            alertType: 'error',
            message: 'Please choose an available date in White.'
          };
          this.setState({ alertErrorMsg: messageObj.message });
          return setPromise(false);
        } else {
          this.setState({ startSpinner: true });
          return this.props
            .updateScheduleDetails(submitValues)
            .then(response => {
              if (response && response.payload && response.payload.status === 200) {
                this.props.history.push('tracking_measure');
              } else {
                const errorMessage = parse(response?.payload?.data?.errorDetails?.errors?.[0]?.message 
                || process.env.REACT_APP_MSC_CONTACT_US);
                this.setState({errorMessage})
              }
              return response;
            })
            .catch(error => {
              const messageObj = {
                alertType: 'error',
                message:
                  'Error in updating schedule entry details. Please try again.'
              };
              if (error?.response?.data?.status?.statusCode !== '00') {
                messageObj.message = error.response.data?.errorDetails?.errors?.[0]?.message
              }
              this.setState({ alertErrorMsg: messageObj.message });
            })
            .finally(() => {
              this.setState({ startSpinner: false })
            });
        }
      } else {
        if (
          !this.isDatePresentInList(
            moment(this.state.scheduledDate).format('YYYY-MM-DD')
          )
        ) {
          const messageObj = {
            alertType: 'error',
            message: 'Please choose an available date in GREEN.'
          };
          this.setState({ alertErrorMsg: messageObj.message });
          return setPromise(false);
        } else {
          this.setState({ startSpinner: true });
          return this.props
            .createScheduleDetails(submitValues)
            .then(response => {
              if (response && response.payload && response.payload.status === 200) {
                this.props.history.push('tracking_measure');
              } else {
                const errorMessage = parse(response?.payload?.data?.errorDetails?.errors?.[0]?.message 
                || process.env.REACT_APP_MSC_CONTACT_US);
                this.setState({errorMessage})
              }
              return response;
            })
            .catch(Error => {
              const messageObj = {
                alertType: 'error',
                message:
                  'Error in creating schedule entry details. Please try again.'
              };
              this.setState({ alertErrorMsg: messageObj.message });
            })
            .finally(() => {
              this.setState({ startSpinner: false })
            });
        }
      }
    }
  };
  pickerOpen = () => {
    this.props.getMessageToShow({message: '', alertType: null});
    if (!this.props.transactionDetails.availableDates.length) {
      if (this.props.transactionDetails.dceResponseSucess && new Date(this.props.transactionDetails.scheduleDate) > new Date()) {
        // if dceResponse is true and scheduled date is future date
        this.setState({ errorMessage: '', isDateOpen: true});
        this.props.buttonValidation(true);
      }else if(this.props.transactionDetails.dceResponseSucess === false && new Date(this.props.transactionDetails.scheduleDate) > new Date()) {
        this.props.buttonValidation(false);
          this.setState({ 
            errorMessage: this.props.transactionDetails.dceErrorMessage ? parse(this.props.transactionDetails.dceErrorMessage) : parse(process.env.REACT_APP_MSC_CONTACT_US)
            , isDateOpen: true });
      }else {
        this.props.buttonValidation(false);
        this.setState({ 
          errorMessage: this.props.transactionDetails.dceErrorMessage ? parse(this.props.transactionDetails.dceErrorMessage) : parse(process.env.REACT_APP_MSC_CONTACT_US)
        });
      }
    } else {
      this.props.buttonValidation(true);
      const {minDateFlag, scheduledDate } = this.state;
      this.setState({ scheduledDate: scheduledDate===null ? new Date(minDateFlag) : moment(scheduledDate).isBefore(Date()) ? new Date(minDateFlag) : scheduledDate });
      this.setState({ isDateOpen: true, error: false, errorMessage: '', isExtscheduleDate: true, isEmptyDate: false });

      //check if schedule date is null
      //this is for 1900 orders, it will make the call on calendar open
      if(this.props.transactionDetails.trackingOrder.length) {
        if((scheduledDate === null || moment(scheduledDate).isBefore(Date())) && 
          this.props.transactionDetails.timeWindowFlag !== null && 
          this.props.transactionDetails.timeWindowFlag) {
            this.setState({ calendarDate: moment(new Date(minDateFlag)).format('YYYY-MM-DD')})
            var trackingNumbers = [];
            this.props.transactionDetails.trackingOrder.map(tracking => {
              trackingNumbers.push(tracking.trackingNumber);
            })
            let windowValues = {
              deliveryDate: moment(new Date(minDateFlag)).format('YYYY-MM-DD'),
              trackingNumbers: trackingNumbers,
              transactionRef: getTransactionRef()
            }
            this.setState({ loadingComplete: false, loadSpinner: true, timeWindowSelected: false, timeWindowAvailable: false });
            //this.getDCETimeWindows(windowValues);
            this.getUTCForTimeWindowsCall(windowValues);
        } 
      }
    }
  }
  pickerClose = () => {
    let scheduleDate = this.state.scheduledDate;
    let isKeyExit;
    if(localStorage.getItem('isKeyExit')){
      isKeyExit =  localStorage.getItem('isKeyExit');
    }
    this.setState({isDateOpen: false});
    this.setState({error: false});
    if((scheduleDate && isKeyExit === undefined) || (scheduleDate && isKeyExit==='false')){
      this.setState({ isEmptyDate: false });
      localStorage.removeItem('isKeyExit');
    } else if(scheduleDate && isKeyExit==='true') {
      this.setState({ isEmptyDate: true });
      localStorage.removeItem('isKeyExit');
    }
  }
  dateFormat = (dateVal) => {
    let finalDate = moment.utc(dateVal).format('LLLL').substring(0, moment.utc(dateVal).format('LLLL').length - 8)
    return finalDate;
  }
  renderInput = (props) => {
    let date = props.inputProps.value !== '' ? this.dateFormat(props.inputProps.value) : '';
    //let scheduleDate = this.state.scheduledDate;
    return (
      <TextField
        type="type"
        label={props.label}
        onClick={this.pickerOpen}
        variant="outlined"
        onChange={props.inputProps.onChange}
        Input="disable.numbers"
        value={date}
        error={!(date.length) || !(this.state.isExtscheduleDate)}
        inputRef={props.inputRef}
        InputProps={{
          endAdornment: props.InputProps?.endAdornment, readOnly: true
        }}
      />
    )
  };

  acceptMethods = renderTimeWindows => {
    // Parent stores the method that the child passed
    this.renderTimeWindows = renderTimeWindows;
  };

  timeWindowValidation = () => {
    const messageObj = {
      alertType: 'error',
      message: 'Select a time window to continue.'
    };
    this.props.getMessageToShow(messageObj);
  }

  handleTimeWindowData = data => {
    this.setState({ 
      timeWindowData: data,
      timeWindowSelected: data.windowSelected,
      windowStartTime: data.startTime,
      windowEndTime: data.endTime,
      greenFlagAvailable: data.greenFlagAvailable,
      greenFlagSelected: data.greenFlagSelected,
      routeID: data.routeID,
      stopNumber: data.stopNumber,
      score: data.score
     });
    this.props.getMessageToShow({message: '', alertType: null});
  }


  handleValidWindowsFlag = flag => {
    this.setState({      
      loadingComplete: flag,
      loadSpinner: !flag
    });
  }
  
  render() {
    const validDate = this.state.requestDate === this.state.calendarDate ? true : false;
    const highlightWithRanges = [
      {
        'react-datepicker__day--highlighted-custom-1': this.state.highlightDates
      },
      {
        'react-datepicker__day--highlighted-custom-2': this.state
          .redHighlightedDate
      }
    ];
    return (
      <div className="">
        <div>{this.state.startSpinner && <TrackingSpinner />}</div>
        {!this.state.startSpinner &&
          (
            <div className={"date-picker-p " + (this.state.isEmptyDate || (this.state.isprevDateEmpty && this.props.transactionDetails.scheduleDate && !this.state.isExtscheduleDate) ? 'error-color' : '')}>
              {this.state.errorMessage && <p className='err-msg dceError' >{this.state.errorMessage}</p>}
              {this.props.transactionDetails.hjScheduleDate && this.props.transactionDetails.hjScheduleDate !== "1900-01-01" && <p className='body-copy prev-date-msg currentDate' style={{ whiteSpace: 'nowrap' }}>Current Scheduled Delivery or Return Pickup Date: <span className='sgreen'>{this.dateFormat(this.props.transactionDetails.hjScheduleDate)}</span></p>}
              {this.state.currentTimeWindow &&(<p className='body-copy prev-date-msg currentDate' style={{ whiteSpace: 'nowrap' }}>Current Scheduled Time Window: <span className='sgreen'>{this.state.currentTimeWindow}</span></p>)}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  readOnly={this.state.timeWindowEligible === true ? !this.state.timeWindowAvailable : false}
                  label="Delivery or Return Pickup Date"
                  onOpen={this.pickerOpen}
                  value={this.state.scheduledDate}
                  className="date-btn"
                  minDate={new Date(this.state.minDateFlag)}
                  maxDate={new Date(this.state.maxDateFlag)}
                  renderDay={this.renderWeekPickerDay}
                  allowKeyboardControl="true"
                  onChange={this.handleChange}
                  renderInput={this.renderInput}
                  open={this.state.isDateOpen}
                  disableCloseOnSelect={true}
                  onClose={this.pickerClose}
                  initialFocusedDate={new Date(this.state.minDateFlag)}
                  aria-describedby="invalid-date"
                />
              </LocalizationProvider>
              <p className='custom-err' role="alert" id="invalid-date">{this.state.isEmptyDate && <span>Select a valid date to continue.</span>}</p>
              {(
                this.state.isprevDateEmpty &&
                this.props.transactionDetails.scheduleDate &&
                !this.state.isExtscheduleDate) &&
                <p className='custom-err'>
                  Your current selected date is no longer available. To reschedule, select a new date.
                </p>
              }
              {this.state.timeWindowAvailable && !this.state.isEmptyDate && validDate && <TimeWindow 
              timeWindowData={this.handleTimeWindowData} 
              shareMethods={this.acceptMethods.bind(this)}
              changedDate={this.state.calendarDate}
              requestDate={this.state.requestDate}
              loadingComplete={this.state.loadingComplete}              
              windowsValid={this.handleValidWindowsFlag}
              unselectedTW={this.state.unselectedTW}
              />}
              {!this.state.loadingComplete && 
              this.state.timeWindowEligible && 
              this.state.loadSpinner && <TrackingSpinner />}
              <InnovelFlashMessage />
              <Button
                disableRipple
                role="link"
                variant="contained"
                className='action-btn'
                sx={{ backgroundColor: '#3071a9', maxWidth: 400, marginBottom: '16px' }}
                onClick={this.state.activeButton === true ? this.handleClick : ()=> {}}
              >
                Continue
              </Button>
              <a style={{
                display: 'block', textAlign: 'center', color: '#0060A9', width: '60px',
                margin: '0 auto'
              }}
                onClick={this.previous}
                disabled={this.props.disableFinish}
                hidden={this.state.orderComplete}
                href="#">Back</a>
            </div>
          )}
          <br role="presentation" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactionDetails: state.transactionDetails
  };
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      { updateScheduleDetails, createScheduleDetails, getUTCTime, getMessageToShow, getTimeWindows },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleForm);
