import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { apiRepo } from 'repository/apiRepo';
import 'styles/_deliveryform.scss';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'styles/_font.scss';
import CheckMark from 'images/Checkmark.png'
import { getItems } from 'reducers/formFunctions';
import { getMessageToShow } from 'actions/index';
import { setPromise } from 'containers/common/innovel_util';
import QuestionAnswerWrapper from './questionAnswerWrapper';
import parse from 'html-react-parser';

export class NewDeliveryDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.props.shareMethods(this.storeDeliveryDetails);
    this.state = {
      isOpen: false,
      alertErrorMsg: null,
      errorListState: [],
      errorTrackingNumbersState: []
    };
    this.errorIDList = [];
    this.errorTrackingNumbers = [];
    this.notSelectedForReplacement = [];
    this.selectedForHaulaway = [];
    this.deliveryFormErrorRef = React.createRef();
  }

  validateForm = qa => {
    if (qa.question.answerType === 'RADIO_BUTTON') {
      if (qa.answer.currentValue === null && qa.answer.newValue === null) {
        if (!_.find(this.errorIDList, ['id', qa.question.id])) {
          this.errorIDList = [...this.errorIDList, { rank: qa.question.rank, id: qa.question.id }];
        }
        return false;
      } else if (
        qa.question.semanticType === 'PHONE_NUMBER' &&
        qa.answer.newValue === false &&
        qa.question.subQuestionAnswers &&
        qa.question.subQuestionAnswers.length > 0
      ) {
        return !qa.question.subQuestionAnswers.some((subQA) => this.validateForm(subQA) === false);
      } else if (
        qa.question.semanticType !== 'PHONE_NUMBER' &&
        qa.answer.newValue === true &&
        qa.question.subQuestionAnswers &&
        qa.question.subQuestionAnswers.length > 0
      ) {
        return !qa.question.subQuestionAnswers.some((subQA) => this.validateForm(subQA) === false);
      }
      return true;
    } else if (qa.question.answerType === 'PHONE_NUMBER') {
      if (qa.answer.newValue === null || qa.answer.newValue.length !== 10) {
        if (!_.find(this.errorIDList, ['id', qa.question.id])) {
          this.errorIDList = [...this.errorIDList, { rank: qa.question.rank, id: qa.question.id }];
        }
        return false;
      }
    }
    else if (qa.question.answerType === 'CHECK_BOX') {
      let isChecked = false;
      if (qa.question.semanticType === 'HAUL_AWAY_BULK') {
        this.notSelectedForReplacement = qa.answer.map(item => item.answer.trackingNumber); // not selected for replacement
        qa.answer.forEach(item => {
          if (item.answer.newValue) {
            this.selectedForHaulaway.push(item.answer.trackingNumber)
          }
        })
        isChecked = qa.answer.some(item => item.answer.newValue === true);
      } else {
        isChecked = qa.answer.some(item => item.newValue === true);
      }

      if (isChecked === false) {
        if (!_.find(this.errorIDList, ['id', qa.question.id])) {
          this.errorIDList = [...this.errorIDList, { rank: qa.question.rank, id: qa.question.id }];
        }
        return false;
      } else if (
        qa.question.subQuestionAnswers &&
        qa.question.subQuestionAnswers.length > 0
      ) {
        return !qa.question.subQuestionAnswers.some((subQA) => this.validateForm(subQA) === false);
      }
    } else if (qa.question.answerType === 'DROP_DOWN_STRING') {
      if (
        qa.answer.newValue === null
      ) {
        if (!_.find(this.errorIDList, ['id', qa.question.id])) {
          this.errorIDList = [...this.errorIDList, { rank: qa.question.rank, id: qa.question.id }];
        }
        return false;
      }
      const selectedChoice = qa.question.choices[0].filter(choice => choice.value === qa.answer.newValue);
      if (selectedChoice &&
        selectedChoice.length &&
        selectedChoice[0].question &&
        qa.question.subQuestionAnswers
        && qa.question.subQuestionAnswers.length) {
        return !qa.question.subQuestionAnswers.some((subQA) => this.validateForm(subQA) === false);
      }
      return true;
    } else if (qa.question.answerType === 'TEXT_BOX') {
      if (qa.answer.newValue === null || qa.answer.newValue === '') {
        if (!_.find(this.errorIDList, ['id', qa.question.id])) {
          this.errorIDList = [...this.errorIDList, { rank: qa.question.rank, id: qa.question.id }];
        }
        return false;
      }
    } else if (qa.question.answerType === 'DROP_DOWN_NUMBER') {
      let allSelected = true;
      let oneSelected = false;
      let numberError = false;
      getItems(qa.answer).forEach(answer => {
        if (
          (!this.notSelectedForReplacement.length || this.notSelectedForReplacement.includes(answer.answer.trackingNumber)) &&
          ((answer.newValue === null) ||
            (answer.newValue === 0))
        ) {
          allSelected = false;
        } else {
          oneSelected = true;
        }
        const tracking = answer.answer.trackingNumber;
        if (this.notSelectedForReplacement.includes(tracking)) {
          if (this.selectedForHaulaway.includes(tracking)
            && ((answer.newValue === null) ||
              (answer.newValue === 0) ||
              isNaN(answer.newValue))
          ) {
            numberError = true;
            this.errorTrackingNumbers.push(tracking);
          }
        }
      });
      if (!Array.isArray(qa.answer) && (
        (qa.answer.newValue === null) ||
        (qa.answer.newValue === 0) ||
        isNaN(qa.answer.newValue))) {
        numberError = true;
        qa.answer?.answer?.trackingNumber && this.errorTrackingNumbers.push(qa.answer.answer.trackingNumber);
      }
      if (numberError || (allSelected === false && oneSelected === false)) {
        this.selectedForHaulaway = [];
        if (!_.find(this.errorIDList, ['id', qa.question.id])) {
          this.errorIDList = [...this.errorIDList, { rank: qa.question.rank, id: qa.question.id }];
        }
        return false;
      }
    } else {
      return true;
    }
  };

  clearErrorId = (errorId, trackingNumbers) => {
    if (!trackingNumbers || !trackingNumbers.length) {
      this.errorIDList = this.errorIDList.filter(obj => obj.id !== errorId);
      this.setState({ errorListState: this.errorIDList })
    }
    if(Array.isArray(trackingNumbers)) {
      this.setState({ errorTrackingNumbersState: trackingNumbers })
      this.errorTrackingNumbers = trackingNumbers;
    }
  }

  storeDeliveryDetails = () => {
    this.props.getMessageToShow({ message: '', alertType: null });
    let questionAnswer = {
      questionAnswer: this.props.orderDeliveryDetails.questionAnswer
        ? this.props.orderDeliveryDetails.questionAnswer.questionAnswer
        : []
    };
    let validationPasses;
    this.errorIDList = [];
    questionAnswer.questionAnswer.map((qa, index) => {
      if (this.validateForm(qa) === false) {
        validationPasses = false;
      }
    });
    const errorListState = _.sortBy(this.errorIDList, ['rank', 'id']);
    this.setState({ errorListState, errorTrackingNumbersState: this.errorTrackingNumbers });
    if (validationPasses !== false) {
      let updatedObject = _.cloneDeep(questionAnswer);
      const { masterQA, ...orderDeliveryDetailsCurrent } = this.props.orderDeliveryDetails;
      const orderDeliveryDetails = _.cloneDeep(orderDeliveryDetailsCurrent);

      // Add unchecked haul_away answers back with false value, change number dropdown to 0 for them.
      // unchecked haul_away answers are not available in orderDeliveryDetails object, so add the unchecked answers to answer object
      // and set the value to false. Also, set the numberdropdown values to 0 for them
      if (Array.isArray(orderDeliveryDetails.questionAnswer.questionAnswer)
        && orderDeliveryDetails.questionAnswer.questionAnswer[5]
        && orderDeliveryDetails.questionAnswer.questionAnswer[5].question
        && orderDeliveryDetails.questionAnswer.questionAnswer[5].question.semanticType === 'HAUL_AWAY'
        && orderDeliveryDetails.questionAnswer.questionAnswer[5].question.subQuestionAnswers
        && Array.isArray(orderDeliveryDetails.questionAnswer.questionAnswer[5].question.subQuestionAnswers)
        && orderDeliveryDetails.questionAnswer.questionAnswer[5].question.subQuestionAnswers[0]
        && orderDeliveryDetails.questionAnswer.questionAnswer[5].question.subQuestionAnswers[0].answer
      ) {
        const haulawayCheckboxAns = orderDeliveryDetails.questionAnswer.questionAnswer[5].question.subQuestionAnswers[0].answer;
        const masterHaulawayAns = masterQA[5].question.subQuestionAnswers[0].answer;
        if (haulawayCheckboxAns.length !== masterHaulawayAns.length) {
          let answers = [];
          const unselectedTrackingNum = [];
          masterHaulawayAns.forEach(option => {
            const index = haulawayCheckboxAns.findIndex(x => x.answer.trackingNumber === option.answer.trackingNumber)
            if (index !== -1) {
              answers.push({ ...haulawayCheckboxAns[index], replacementOrder: false });
            } else {
              unselectedTrackingNum.push(option.trackingNumber);
              const newOption = Object.assign({}, option);
              newOption.answer.newValue = false;
              answers.push({ ...newOption, replacementOrder: true });
            }
          })
          orderDeliveryDetails.questionAnswer.questionAnswer[5].question.subQuestionAnswers[0].answer = answers;
          if (orderDeliveryDetails.questionAnswer.questionAnswer[5].question.subQuestionAnswers[0].question.subQuestionAnswers
            && orderDeliveryDetails.questionAnswer.questionAnswer[5].question.subQuestionAnswers[0].question.subQuestionAnswers[0]
            && orderDeliveryDetails.questionAnswer.questionAnswer[5].question.subQuestionAnswers[0].question.subQuestionAnswers[0].answer) {
            const numberAns = orderDeliveryDetails.questionAnswer.questionAnswer[5].question.subQuestionAnswers[0].question.subQuestionAnswers[0].answer;
            if (Array.isArray(numberAns)) {
              const updatedNumAns = [];
              numberAns.forEach(ans => {
                if (unselectedTrackingNum.includes(ans.answer.trackingNumber)) {
                  updatedNumAns.push({ ...ans, newValue: 0 });
                } else {
                  updatedNumAns.push(ans);
                }
              })
              orderDeliveryDetails.questionAnswer.questionAnswer[5].question.subQuestionAnswers[0].question.subQuestionAnswers[0].answer = updatedNumAns;
            }
          }
        }
      }

      //Look for TRUCK semantic type and remove the statement
      //statement not needed by backend and causing WAF rules issues
      if(Array.isArray(updatedObject.questionAnswer)) {
        updatedObject.questionAnswer.forEach(qa => {
          if (qa.question.semanticType === "TRUCK") {
            qa.question.statements = null;
          }
        });
      }

      let putObject = {
        ...orderDeliveryDetails,
        transactionRef: this.props.transactionRef,
        questionAnswer: updatedObject
      };

      return apiRepo
        .serviceCall('put', '/v1/schedule/delivery/', putObject)
        .then(response => {
          this.props.disableBtn(false);
          return response;
        })
        .catch(error => {
          this.props.disableBtn(false);
          const message = process.env.REACT_APP_MSC_CONTACT_US;
          let messageObj = {
            alertType: 'error',
            message
          };
          if (error?.response?.data?.status?.statusCode !== '00') {
            messageObj.message = parse(error?.response?.data?.errorDetails?.errors?.[0]?.message || message);
          }
          this.props.getMessageToShow(messageObj)
          this.setState({ alertErrorMsg: messageObj.message });
        });
    } else {
      this.setState({formError: true});
      return setPromise(false);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.formError) {
      this.setState({formError: false})
      this.deliveryFormErrorRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  openModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  render() {
    let rankedQuestions;
    if (this.props.orderDeliveryDetails
      && this.props.orderDeliveryDetails.questionAnswer
      && this.props.orderDeliveryDetails.questionAnswer.questionAnswer
    ) {
      rankedQuestions = this.props.orderDeliveryDetails.questionAnswer.questionAnswer.sort(
        (a, b) => (a.question.rank > b.question.rank ? 1 : -1)
      );
    }

    return (
      <section>
        <div className="row" ref={this.deliveryFormErrorRef}>
          <div style={{ display: this.state.errorListState.length ? 'block' : 'none', padding: '0px' }} className='col traking-ele'>
            <div id="noSchedule" role="alert" tabIndex="-1" className="err-msg" style={{ float: "left", width: "100%" }}>
              <p>
                All fields are required.
              </p>
            </div>
          </div>
        </div>
        <div>
          {rankedQuestions &&
            rankedQuestions.map((qa, index) => {
              let indexArray = [];
              return (
                <div key={index} style={{ fontStyle: 'normal', fontSize: '14px', lineHeight: '20px', marginBottom: '12px' }}>
                  <QuestionAnswerWrapper
                    qa={qa}
                    key={index}
                    index={index}
                    indexArray={indexArray}
                    errorIDs={this.state.errorListState}
                    errorTrackingNumbers={this.state.errorTrackingNumbersState}
                    clearErrorId={this.clearErrorId}
                  />
                </div>
              );
            })}
          {this.props.orderDeliveryDetails &&
            this.props.orderDeliveryDetails.questionAnswer &&
            this.props.orderDeliveryDetails.questionAnswer.statements &&
            Array.isArray(this.props.orderDeliveryDetails.questionAnswer.statements) &&
            this.props.orderDeliveryDetails.questionAnswer.statements.map(
              (statement, index) => {
                let indexArray = [];
                return <p key={index} style={{ fontStyle: 'normal', fontSize: '14px', lineHeight: '20px' }}>
                  <img src={CheckMark} alt='check mark' style={{ width: '13.33px', height: '10.13px', marginRight: '9.33px', marginBottom: '5px' }}></img>
                  {parse(statement.statement.statement)}
                </p>;
              }
            )}
          <div style={{ marginTop: '16px' }}>{this.state.alertErrorMsg && <div className='error-qna'>{this.state.alertErrorMsg}</div>}</div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ getMessageToShow }, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(NewDeliveryDetailsForm);
