import CryptoJS from "crypto-js";

export const setPromise = function(value) {
  return new Promise((resolve, reject) => {
    setTimeout(function() {
      var returnBooleanValue = value;
      returnBooleanValue ? resolve(returnBooleanValue) : reject(Error);
    }, 1000);
  });
};

export const str2bool = function(value) {
  if (value && typeof value === 'string') {
    if (value.toLowerCase() === 'true') return true;
    if (value.toLowerCase() === 'false') return false;
  }
  return value;
};

export const getTransactionRef = () => {
  return sessionStorage.getItem('transactionRef');
};

export const getTrackingNumber = () => {
  return sessionStorage.getItem('trackingNumber');
};


export const setSessionStorage = (transactionRef, trackingNumber) => {
  sessionStorage.setItem('transactionRef', transactionRef);
  sessionStorage.setItem('trackingNumber', trackingNumber);
};

export const clearSessionStorage = () => {
  sessionStorage.removeItem('transactionRef');
  sessionStorage.removeItem('trackingNumber');
  sessionStorage.removeItem("utcTime");
  localStorage.removeItem("dceWindows");
  localStorage.removeItem("timerCount");
  localStorage.removeItem("timerStartTime");

  //removes session keys when users return to landing or home page
  var urlPath = window.location.pathname;
  if((urlPath === "/userselfschedule" || urlPath === "/tracking_confirmation") && sessionStorage !== null && sessionStorage.length > 0) {
    Object.keys(sessionStorage).map(tracking => {
      sessionStorage.removeItem(tracking);
    })
  }
};

export const checkboxSessionStorage = (trackingNumber, isChecked) => {
  if(isChecked) {
    sessionStorage.setItem(trackingNumber, isChecked);
  } else {
    sessionStorage.removeItem(trackingNumber);
  }
};

export const phoneNoFormat = value => {
  if (value) {
    let output = '';
    value.replace(/^[^X\d]*([\dX]{0,3})[^X\d]*([\dX]{0,3})[^X\d]*([\dX]{0,4})/, function(
      match,
      g1,
      g2,
      g3
    ) {
      if (g1.length) {
        output = output + g1;
        if (g1.length === 3) {
          if (g2.length) {
            output += '-' + g2;
            if (g2.length === 3) {
              output += '-';
              if (g3.length) {
                output += g3;
              } else if (output.charAt(output.length - 1) === '-') {
                output = output.substring(0, output.length - 1);
              }
            }
          } else if (output.charAt(output.length - 1) === ')') {
            output = output.substring(0, output.length - 1);
          }
        }
      }
    });
    return output;
  }
  return null;
};

export const phoneNoFormatInput = value => {
  if (value) {
    let output = '';
    value.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function(
      match,
      g1,
      g2,
      g3
    ) {
      if (g1.length) {
        output = output + g1;
        if (g1.length === 3) {
          if (g2.length) {
            output += '-' + g2;
            if (g2.length === 3) {
              output += '-';
              if (g3.length) {
                output += g3;
              } else if (output.charAt(output.length - 1) === '-') {
                output = output.substring(0, output.length - 1);
              }
            }
          } else if (output.charAt(output.length - 1) === ')') {
            output = output.substring(0, output.length - 1);
          }
        }
      }
    });
    return output;
  }
  return null;
};

export const plainPhoneNo = value => {
  if (value) {
    let output = '';
    value.replace(/^[^X\d]*([X\d]{0,3})[^X\d]*([X\d]{0,3})[^X\d]*([X\d]{0,4})/, function(
      match,
      g1,
      g2,
      g3
    ) {
      if (g1.length) {
        output = output + g1;
        if (g1.length === 3) {
          
          if (g2.length) {
            output += g2;
            if (g2.length === 3) {
            
              if (g3.length) {
                output += g3;
              } else if (output.charAt(output.length - 1) === '-') {
                output = output.substring(0, output.length - 1);
              }
            }
          } else if (output.charAt(output.length - 1) === ')') {
            output = output.substring(0, output.length - 1);
          }
        }
      }
    });
    return output;
  }
  return null;
};

export const capitalize = str => {
  return str.replace(/(?:^|\s)\S/g, function(a) {
    return a.toUpperCase();
  });
};

export const RegexMatch = (str, mode) => {
  var matched;
  switch(mode)
  {
    case 1:
      if(str.match((/^(?!.*select|.*update|.*shutdown|.*insert|.*table|.*drop|.*exec|.*delete)([a-zA-Z0-9]+)$/gi)))
        matched = true;
      else
        matched = false;
    break;
    case 2:
      if(str.match(/(select|update|shutdown|insert|table|drop|exec|delete|[\.]|[!@$%^&*()+_=,/<>?;:'"{}~`#|]|[\\[]|[\]]|[-])/gi))
        matched= true;
      else 
        matched = false;
    break;
    case 3:
      if(str.match((/^(?!.*select|.*update|.*shutdown|.*insert|.*table|.*drop|.*exec|.*delete)([0-9]+)$/gi)))
        matched = true;
      else
        matched = false;
    break;
  }

  return matched;
}

export const QuestionLabels = (semanticObject, semanticType) => {
  var labels = {};
  semanticObject.map((type) => {
    if(type.semanticType == semanticType) {
      labels = {
        id: type.id,
        label: type.label,
        inputId: type.inputId,
        subQuestionLabel: type.subQuestionLabel,
        errorId: type.errorId,
        errorMessage: type.errorMessage
      }
    }
  })

  return labels;
}

export const encryptedString = (key, secret) => {
  var encKey = CryptoJS.AES.encrypt(key, secret);

  return encKey.toString();
}

export const decryptedString = (key, secret) => {
  var decKey = CryptoJS.AES.decrypt(key, secret);

  return decKey.toString(CryptoJS.enc.Utf8);
}
